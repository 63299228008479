import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ChaiseGamingProductInfoSlider from "../ChaiseGamingProductInfoSlider"

const ChaiseGamingSliderSection = ({ intl, data }) => {
  return (
    <ChaiseGamingSliderSectionWrapper>
      <Container>
        {data.section_title && (
          <SectionTitle
            dangerouslySetInnerHTML={{
              __html: data.section_title,
            }}
          />
        )}
        {data.first_slider && (
          <ChaiseSection>
            <ChaiseGamingProductInfoSlider data={data.first_slider} />
          </ChaiseSection>
        )}
        {data.second_slider && (
          <ChaiseSection>
            <ChaiseGamingProductInfoSlider data={data.second_slider} />
          </ChaiseSection>
        )}
        {data.third_slider && (
          <ChaiseSection>
            <ChaiseGamingProductInfoSlider data={data.third_slider} />
          </ChaiseSection>
        )}
      </Container>
    </ChaiseGamingSliderSectionWrapper>
  )
}

const ChaiseGamingSliderSectionWrapper = styled.div`
  display: flex;
  width: 100%;
`

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  margin: 0px auto;
  align-items: center;
`

const SectionTitle = styled.h2`
  width: 100%;
  font-size: 40px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    text-align: left;
    font-size: 36px;
    margin-bottom: 10px;
  }
`

const ChaiseSection = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px;
`

export default injectIntl(ChaiseGamingSliderSection)

import React, { useState } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
import Slider from "react-slick"

const RightArrows = props => {
  const { className, style, onClick } = props
  return (
    <button
      className={className + " slider-prev-next-button next"}
      type="button"
      aria-label="Next"
      onClick={onClick}
    >
      <svg className="slick-button-icon" viewBox="0 0 100 100">
        <path
          d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
          transform="translate(100, 100) rotate(180)"
        ></path>
      </svg>
    </button>
  )
}

const PrevArrows = props => {
  const { className, style, onClick } = props
  return (
    <button
      className={className + " slider-prev-next-button prev"}
      type="button"
      aria-label="Previous"
      onClick={onClick}
    >
      <svg className="slick-button-icon" viewBox="0 0 100 100">
        <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"></path>
      </svg>
    </button>
  )
}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  nextArrow: <RightArrows />,
  prevArrow: <PrevArrows />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
  ],
}

const ChaiseGamingProductInfoSlider = ({ intl, data }) => {
  const [active, setacitve] = useState([])
  const handleOnClickActive = i => {
    let arr = [...active]
    arr[i] = !arr[i]
    setacitve(arr)
  }
  return (
    data && (
      <Wrapper>
        <SliderWrapper>
          {isBrowser && (
            <Slider {...settings}>
              {data &&
                data.map((item, index) => {
                  return (
                    item && (
                      <ProductCard key={index.toString()}>
                        {index == 0 && item.title ? (
                          <SliderTitleBox>
                            <SliderTitle
                              dangerouslySetInnerHTML={{
                                __html: item.title,
                              }}
                            />
                          </SliderTitleBox>
                        ) : (
                          <TopSection>
                            <ProductTextBox>
                              {item.title && (
                                <ProductTitle
                                  dangerouslySetInnerHTML={{
                                    __html: item.title,
                                  }}
                                />
                              )}
                              {item.text && (
                                <ProductDescriptionBox
                                  dangerouslySetInnerHTML={{
                                    __html: item.text,
                                  }}
                                  style={{
                                    display: active[index]
                                      ? "flex"
                                      : "-webkit-box",
                                  }}
                                />
                              )}
                              <OpenClose
                                onClick={() => handleOnClickActive(index)}
                              >
                                {active[index] ? "-" : "+"}
                              </OpenClose>
                            </ProductTextBox>
                          </TopSection>
                        )}
                        {item.image && (
                          <ProductBottomInfo>
                            <ProductImage
                              className="lazyload"
                              data-src={item.image}
                              alt={item.alt ? item.alt : ""}
                            />
                          </ProductBottomInfo>
                        )}
                      </ProductCard>
                    )
                  )
                })}
            </Slider>
          )}
        </SliderWrapper>
      </Wrapper>
    )
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;

  .regular.slider {
    display: flex;
  }

  .slick-track {
    display: flex;
  }

  .slick-slider {
    width: 100%;
    position: relative;
    display: flex;

    @media (max-width: 480px) {
      width: calc(100% + 15px);
    }
  }

  .slick-slide {
    @media (max-width: 480px) {
      width: 100%;
      margin: 0px 15px 0px 0px;
    }
  }

  .slick-prev:before,
  .slick-next:before,
  .slick-disabled {
    display: none !important;
  }

  .slider-prev-next-button {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0%, -50%);
    border: 0px;
    background: transparent;
  }

  .slick-button-icon {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }

  .slider-prev-next-button.prev {
    left: 10px;
  }

  .slider-prev-next-button.next {
    right: 10px;
    @media (max-width: 480px) {
      right: 25px;
    }
  }
`

const ProductCard = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
  background: #000;
  color: #fff;
  width: 100%;
  min-width: 372px;
  max-width: 372px;
  height: 400px;
  max-height: 400px;
  overflow: hidden;
  margin: 0px 20px 0px 0px;
  border-radius: 5px;
  position: relative;

  @media (max-width: 600px) {
    margin: 0px 15px 0px 0px;
  }

  @media (max-width: 480px) {
    min-width: 100%;
    max-width: 100%;
    height: 380px;
    max-height: 380px;
  }
`

const SliderTitleBox = styled.div`
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  text-align: center;
  border-radius: 5px;

  @media (max-width: 480px) {
    min-width: 75%;
  }
`

const SliderTitle = styled.p`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  color: #000;
  margin: 0px;
`

const TopSection = styled.div`
  width: 100%;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 40px;
  position: absolute;
  top: 0px;
  overflow: hidden;
  min-height: 120px;
`

const ProductBottomInfo = styled.div`
  width: 100%;
  display: flex;
`

const ProductTextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ProductImage = styled.img`
  width: 100%;
  margin: 0px;
  height: 100%;
  object-fit: cover;
`

const ProductTitle = styled.p`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  margin: 0px 0px 10px;
`

const ProductDescriptionBox = styled.p`
  font-size: 14px;
  line-height: 1.2;
  font-family: "GothamLight";
  font-weight: bold;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

const OpenClose = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
`

export default injectIntl(ChaiseGamingProductInfoSlider)
